import {useNavigate} from 'react-router-dom';
import {I18n} from '@aws-amplify/core';
import {NextButton} from "@pac/platform-ui-components";
import {AiOutlineArrowLeft} from "react-icons/ai";


export const BackButton = ({
                               cancelUrl,
                               label = I18n.get('Back')
                           }: { cancelUrl: string, label?: string }) => {
    const navigate = useNavigate();

    return (
        <>
            {cancelUrl ? (
                <NextButton
                    type="button"
                    leftSection={<AiOutlineArrowLeft/>}
                    onClick={(event: { preventDefault: () => void }) => {
                        event.preventDefault();
                        navigate(cancelUrl);
                    }}
                >
                    {label}
                </NextButton>
            ) : null}
        </>
    );
};
