import {
    Formik,
    FormikProps
} from 'formik';

import {useNavigate} from 'react-router-dom';

import RecordSchema from './RecordSchema';

import {FormComponent} from './FormComponent';

import {
    FormWithActionBar,
    PacFormikComponentState
} from "@pac/platform-ui-components";
import {
    SignInProfile,
    VerbosityLevel
} from "../types.ts";

export const RecordForm = ({
                               record,
                               handleSubmit
                           }: {
    record: SignInProfile,
    handleSubmit: (values: SignInProfile) => void,
}) => {
    const navigate = useNavigate();

    const onSubmit = (values: SignInProfile) => {
        handleSubmit(values);
        navigate('/sign-in-profiles');
    };

    const renderForm = (props: FormikProps<SignInProfile>) => {
        return (
            <FormWithActionBar
                handleSubmit={props.handleSubmit}
                handleCancel={() => navigate('/sign-in-profiles')}
            >
                <FormComponent/>
                <PacFormikComponentState enabled={false} {...props} />
            </FormWithActionBar>
        );
    };

    let initialValues = {
        language: 'en',
        showReturnTo: false,
        passwordAndSso: false,
        verbosity: VerbosityLevel.MEDIUM,
        isActive: false
    } as SignInProfile;

    if (typeof record !== 'undefined') {
        initialValues = record;
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={RecordSchema}
            onSubmit={onSubmit}
        >
            {renderForm}
        </Formik>
    );
};
