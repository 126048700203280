import {BackButton} from './BackButton.tsx';

import {
    Navigate,
    NavLink,
    useParams
} from 'react-router-dom';

import {I18n} from '@aws-amplify/core';

import {
    Box,
    Center,
    Group,
    Stack,
    Text,
} from '@mantine/core';


import {
    NextButton,
    PlatformFieldset
} from "@pac/platform-ui-components";
import {useAccount} from "../../accounts/hooks/useAccount.ts";

export const SelectionComponent = ({handleSSOButtonClick}: { handleSSOButtonClick: () => void }) => {

    const {account: accountId} = useParams();

    const {
        record: account,
    } = useAccount(accountId);

    if (typeof account === 'undefined' || account === null) {
        return <Navigate to="/404"/>;
    }

    let text = [
        I18n.get(
            'You have enabled Sign-In using both password and Single Sign-On (SSO)'
        ),
        I18n.get('Please make a selection below or change Sign-in options'),
    ].join('. ');

    return (
        <Stack gap={'xl'}>

            <PlatformFieldset legend={'Sign In Method Selection'}>

                <Text>{I18n.get(text)}</Text>

                <Center>
                    <Group my={'xl'}>
                        <Box>
                            <NextButton onClick={handleSSOButtonClick}>
                                {I18n.get('Use SSO')}
                            </NextButton>
                        </Box>

                        <Box>{I18n.get('or')}</Box>

                        <Box>
                            <NavLink to={`/accounts/${account.id}/credentials`}>
                                {I18n.get('Use username and password')}
                            </NavLink>
                        </Box>
                    </Group>
                </Center>

            </PlatformFieldset>

            <Group justify="flex-end">
                <BackButton cancelUrl="/"/>
            </Group>

        </Stack>
    );
};
