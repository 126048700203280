import {Box} from '@mantine/core';
import {Application} from "../types.ts";
import {ApplicationProfile} from "./ApplicationProfile.tsx";
import classes from './ApplicationProfile.module.css';

export const ApplicationsList = (
    {
        applications,
    }: {
        applications: Application[],
    }) => {

    const reducedApplications = applications.filter((_, index) => index === 0);

    return (
        <Box>
            {reducedApplications.map((application, index) => {
                const className = index === 0 ? classes['active-profile'] : '';

                return (
                    <ApplicationProfile
                        key={index}
                        application={application}
                        className={className}
                    />
                );
            })}
        </Box>
    );
};
