import {
    useEffect,
    useState
} from 'react';

import {Cache} from 'aws-amplify';

import {NextButton} from "@pac/platform-ui-components";

import {AiOutlineCloudDownload} from 'react-icons/ai';

import {I18n} from '@aws-amplify/core';

import {
    Code,
    Table
} from '@mantine/core';
import config from "../../../config";


const {about} = config;

const METADATA_LOCAL_STORAGE_KEY = 'pac-app-metadata';
const METADATA_LOCAL_STORAGE_VALUE = {
    version: 1,
};

export const VersionControl = () => {
    const [currentVersion, setCurrentVersion] = useState(false);

    useEffect(() => {
        const metadata = Cache.getItem(METADATA_LOCAL_STORAGE_KEY, {
            callback: () => {
                return METADATA_LOCAL_STORAGE_VALUE;
            },
        });

        setCurrentVersion(metadata.version);
    }, []);

    const handleUpgrade = () => {
        // clear
        Cache.clear();
        // store
        Cache.setItem(METADATA_LOCAL_STORAGE_KEY, {
            version: about.latestVersion,
        });
        window.location.href = about.host + '/about';
    };


    return (
        <>
            <Table.Tr>
                <Table.Td>
                    {I18n.get('Latest Version')}:
                </Table.Td>
                <Table.Td colSpan={2}>
                    <Code>{about.latestVersion}</Code>
                </Table.Td>
            </Table.Tr>

            <Table.Tr>
                <Table.Td>
                    {I18n.get('Current Version')}:
                </Table.Td>
                {currentVersion < about.latestVersion ? (
                    <>
                        <Table.Td>
                            <Code>{currentVersion}</Code>
                        </Table.Td>
                        <Table.Td>
                            <NextButton
                                type="button"
                                leftSection={<AiOutlineCloudDownload/>}
                                onClick={handleUpgrade}
                            >
                                {I18n.get('Upgrade')}
                            </NextButton>
                        </Table.Td>
                    </>
                ) : <Table.Td colSpan={2}>{currentVersion}</Table.Td>
                }

            </Table.Tr>
        </>
    );

};
