import {
    Field,
    FormikProps,
} from 'formik';

import {I18n} from '@aws-amplify/core';


import {
    PacFormikInput,
    PacFormikPasswordInput,
    PlatformFieldset
} from "@pac/platform-ui-components";
import {Credentials} from "../types.ts";

export const FormComponent = (props: FormikProps<Credentials>) => {

    const values = props.values;

    return (
        <PlatformFieldset legend={'Sign In'}>
            <Field
                type="email"
                name="email"
                label={I18n.get('Email')}
                value={values.email}
                placeholder={I18n.get('Email')}
                component={PacFormikInput}
                disabled={false}
                my={'xl'}
            />
            <Field
                autoFocus
                tabIndex={0}
                name="password"
                label={I18n.get('Password')}
                placeholder={I18n.get('Password')}
                component={PacFormikPasswordInput}
                my={'xl'}
            />
        </PlatformFieldset>
    );
};
