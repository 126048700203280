import {I18n} from '@aws-amplify/core';

import {useNavigate} from 'react-router-dom';

import {
    Box,
    Title
} from '@mantine/core';
import {NextButton} from '@pac/platform-ui-components';
import {AiOutlineHome} from 'react-icons/ai';

export const PageNotFoundPage = () => {
    const navigate = useNavigate();

    return (
        <Box>
            <Title order={1}>404 - {I18n.get('Page Not Found')}</Title>
            <div
                style={{
                    marginTop: '2em',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                }}
            >
                <NextButton
                    type="button"
                    leftSection={<AiOutlineHome/>}
                    onClick={(event) => {
                        event.preventDefault();
                        navigate('/');
                    }}
                >
                    {I18n.get('Home')}
                </NextButton>
            </div>
        </Box>
    );
};
