import {Field} from 'formik';


import {Stack} from '@mantine/core';
import {
    PacFormikDropdown,
    PacFormikRadioGroup,
    PacFormikSwitch,
    PlatformFieldset
} from "@pac/platform-ui-components";
import {VerbosityLevel} from "../types.ts";

const languages = [
    {
        id: 'en',
        label: 'English'
    },
    {
        id: 'ja',
        label: 'Japanese'
    },
    {
        id: 'bg',
        label: 'Bulgarian'
    },
    {
        id: 'hi',
        label: 'Hindi'
    },
];

const verbosityOptions = [
    {
        value: VerbosityLevel.LOW,
        label: 'low'
    },
    {
        value: VerbosityLevel.MEDIUM,
        label: 'medium'
    },
    {
        value: VerbosityLevel.HIGH,
        label: 'high'
    },
];

const languagesOptions = languages.map((language) => {
    return {
        value: language.id,
        label: language.label
    };
});

export const FormComponent = () => {

    return (
        <Stack gap={'lg'}>

            <PlatformFieldset legend={'Sign-in Profile'}>

                <Field
                    name="showReturnTo"
                    label="Show list of 'return to' applications"
                    options={{
                        labelWhenTrue: 'Show',
                        labelWhenFalse: 'Hide',
                    }}
                    component={PacFormikSwitch}
                    my={'xl'}
                />

                <Field
                    name="passwordAndSso"
                    label="Allow selection of sign-in method (not common)"
                    options={{
                        labelWhenTrue: 'Enabled',
                        labelWhenFalse: 'Disabled',
                    }}
                    component={PacFormikSwitch}
                    my={'xl'}
                />


            </PlatformFieldset>

            <PlatformFieldset legend={'Language and Verbosity'}>
                <Field
                    autoFocus
                    name="language"
                    placeholder="Select a language"
                    label="Perferred language"
                    data={languagesOptions}
                    component={PacFormikDropdown}
                    my={'xl'}
                />

                <Field
                    name="verbosity"
                    label="Verbosity level"
                    component={PacFormikRadioGroup}
                    options={verbosityOptions}
                    my={'xl'}
                />

            </PlatformFieldset>

            <PlatformFieldset legend={'Status'}>
                <Field
                    name="isActive"
                    label="Activate this profile"
                    options={{
                        labelWhenTrue: 'Yes',
                        labelWhenFalse: 'No',
                    }}
                    component={PacFormikSwitch}
                    my={'xl'}
                />

            </PlatformFieldset>
        </Stack>
    );
};