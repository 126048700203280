import {
    useNavigate,
    useParams
} from 'react-router-dom';

import {I18n} from '@aws-amplify/core';

import {
    Box,
    Center,
    Group
} from '@mantine/core';
import {useAccount} from "../../accounts/hooks/useAccount.ts";
import {
    NextButton,
    PlatformFieldset
} from "@pac/platform-ui-components";
import {
    AiOutlineArrowLeft,
    AiOutlineHome
} from "react-icons/ai";


export const FederatedUserPage = () => {

    const {account: accountId} = useParams();
    const {
        record: account,
    } = useAccount(accountId);

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(`/accounts/${account.id}/reset-password/selection`);
    };

    const handleGoHome = () => {
        navigate('/');
    };


    return (
        <PlatformFieldset legend={I18n.get('Reset Password for federated users')}>
            <Center py="xl">
                <Box>
                    Please, use your corporate sign-in provider to reset your password
                </Box>
            </Center>
            <Group py="sm" justify="center">
                <NextButton
                    onClick={handleGoBack}
                    leftSection={<AiOutlineArrowLeft/>}
                >
                    Back
                </NextButton>
                <NextButton
                    variant="outline"
                    onClick={handleGoHome}
                    leftSection={<AiOutlineHome/>}
                >
                    Home
                </NextButton>
            </Group>
        </PlatformFieldset>
    );
};
