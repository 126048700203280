import {useNavigate} from 'react-router-dom';
import {EmailForm} from '../forms/EmailForm';
import {useAccounts} from "../hooks/useAccounts";
import {Account} from "../types.ts";


export const AddPage = () => {
    const navigate = useNavigate();

    const {handleSearchAccounts} = useAccounts();

    const handleSubmit = (values: Account) => {

        handleSearchAccounts(values.email)

        const timer = setTimeout(() => {
            navigate('/accounts');
            return false;
        }, 1000);
        return () => clearTimeout(timer);
    };


    let initialValues = {email: ''} as Account;

    return (
        <EmailForm
            handleSubmit={handleSubmit}
            record={initialValues}
        />
    );
};
