import {
    Box,
    Center,
    Group
} from '@mantine/core';

import {
    useNavigate,
    useParams
} from 'react-router-dom';
import {useAccount} from "../../accounts/hooks/useAccount";
import {
    AiOutlineArrowRight,
    AiOutlineCheck
} from "react-icons/ai";
import {NextButton} from '@pac/platform-ui-components';

export const AcceptanceConfirmationPage = () => {

    const navigate = useNavigate();

    const {account: accountId} = useParams();

    const {
        record: account,
    } = useAccount(accountId);

    if (account === null) {
        throw new Error('Account is null');
    }

    return (
        <Center>
            <Group p={'xl'}>
                <AiOutlineCheck size={'3em'} color={'green'}/>
                <Box p={'xl'}>Terms and Conditions Accepted</Box>
                <NextButton variant={'outline'} rightSection={<AiOutlineArrowRight size={'1em'} color={'green'}/>}
                            onClick={() => {
                                return navigate(`/accounts/${account.id}/redirect`);
                            }}>Continue</NextButton>
            </Group>
        </Center>
    );
};

