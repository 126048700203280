import {ApplicationsControl} from '../components/ApplicationsControl';
import {SignInProfilesControl} from '../components/SignInProfilesControl.tsx';
import {VersionControl} from '../components/VersionControl';
import {UserAccountsControl} from '../components/UserAccountsControl';

import {I18n} from '@aws-amplify/core';

import {
    Box,
    Stack,
    Table
} from '@mantine/core';
import config from "../../../config";
import {PlatformFieldset} from '@pac/platform-ui-components';


const {about} = config;


export const IndexPage = () => {
    return (
        <Stack gap="lg">
            <PlatformFieldset legend={I18n.get('About')}>

                <Table striped verticalSpacing={'md'}>
                    <Table.Tbody>
                        <Table.Tr>
                            <Table.Td>
                                <Box>{I18n.get('Application Name')}:</Box>
                            </Table.Td>
                            <Table.Td colSpan={2}>
                                <Box>{about.name}</Box>
                            </Table.Td>
                        </Table.Tr>

                        <VersionControl/>

                    </Table.Tbody>
                </Table>
            </PlatformFieldset>

            <PlatformFieldset legend={I18n.get('Resources')}>
                <Table striped verticalSpacing={'sm'}>
                    <Table.Tbody>
                        <UserAccountsControl/>
                        <ApplicationsControl/>
                        <SignInProfilesControl/>
                    </Table.Tbody>
                </Table>
            </PlatformFieldset>
        </Stack>
    );
};
