import {useEffect} from 'react';

import {useParams} from 'react-router-dom';

import {RecordForm} from '../forms/RecordForm';
import {useSignInProfile} from "../hooks/useSignInProfile.ts";
import {useSignInProfiles} from "../hooks/useSignInProfiles.ts";
import {PlatformLoader} from '@pac/platform-ui-components';

export const EditPage = () => {

    const {id} = useParams();
    const {
        record,
        handleUpdateSignInProfile
    } = useSignInProfile(id as string)
    const {handleFetchSignInProfiles} = useSignInProfiles()

    useEffect(() => {
        handleFetchSignInProfiles();
    }, []);


    if (!record) {
        return (<PlatformLoader message="Loading Sign-In Options...Please wait!"/>);
    }

    return (
        <RecordForm
            record={record}
            handleSubmit={handleUpdateSignInProfile}
        />
    );
};
