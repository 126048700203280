import {useEffect} from 'react';

import {ReturnToSection} from '../components/ReturnToSection';
import {useApplications} from "../hooks/useApplications";
import {useSignInProfiles} from "../../sign-in-profiles/hooks/useSignInProfiles.ts";
import {I18n} from 'aws-amplify';
import {PlatformFieldset} from '@pac/platform-ui-components';


export const IndexPage = () => {

    const {
        handleFetchSignInProfiles,
        activeProfile
    } = useSignInProfiles();
    const show_return_to = activeProfile?.showReturnTo;

    useEffect(() => {
        handleFetchSignInProfiles()
    }, [])


    const {
        applications,
        handleFetchApplications,
        handleSwapPositions
    } = useApplications();


    useEffect(() => {
        handleFetchApplications();
    }, []);


    if (show_return_to === null) {
        return <div>Loading</div>;
    }

    if (show_return_to === true && Array.isArray(applications)) {
        return (
            <PlatformFieldset legend={I18n.get('Return to') + ':'}>
                <ReturnToSection
                    applications={applications}
                    swapPositions={handleSwapPositions}
                />
            </PlatformFieldset>
        );
    } else {
        return null;
    }
};
