import {I18n} from '@aws-amplify/core';
import {
    Box,
    Card,
    Center,
    Grid
} from '@mantine/core';

import {AiOutlineClose} from 'react-icons/ai';
import {NextButton} from '@pac/platform-ui-components';
import {Application} from "../types.ts";
import {useApplications} from "../hooks/useApplications.ts";


export const ApplicationProfile = (
    {
        application,
        className = '',
    }: {
        application: Application,
        className?: string
    }) => {

    const {
        handleDeleteApplication,
        handleFetchApplications,
    } = useApplications();


    return (
        <Card shadow={'sm'} className={className}>
            <Card.Section>
                <Grid p={'sm'}>
                    <Grid.Col span={{
                        base: 10,
                        sm: 12
                    }}>
                        <Box px="sm" mt="sm">
                            {application.origin}
                        </Box>
                    </Grid.Col>

                    <Grid.Col span={{
                        base: 2,
                        sm: 12
                    }}>
                        <Center mt="xs">
                            {!application.isDefault ? <NextButton
                                    variant={'outline'}
                                    leftSection={<AiOutlineClose/>}
                                    onClick={() => {
                                        console.debug('delete record', application);
                                        handleDeleteApplication(application.id)
                                        handleFetchApplications()
                                    }}
                                >{I18n.get('Clear')}</NextButton> :
                                <NextButton disabled={true}>Default</NextButton>}
                        </Center>
                    </Grid.Col>
                </Grid>
            </Card.Section>
        </Card>
    );

};
