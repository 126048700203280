import {AiOutlinePlusCircle} from 'react-icons/ai';
import {I18n} from '@aws-amplify/core';

import {
    Box,
    Center,
    Grid,
    Paper
} from '@mantine/core';

import classes from './AddAccountProfile.module.css';

export const AddAccountProfile = (
    {
        accountsCount = 0,
        onClick
    }: {
        accountsCount?: number,
        onClick: () => void
    }) => {
    return (
        <Paper onClick={onClick} shadow={'sm'} withBorder>
            <Grid gutter="sm" py="lg" className={classes.container}>
                <Grid.Col span={3}>
                    <Center ml={'sm'}>
                        <AiOutlinePlusCircle size={'4em'}/>
                    </Center>
                </Grid.Col>

                <Grid.Col span={9}>
                    <Box mt="lg">
                        {accountsCount > 0
                            ? I18n.get('Use another account')
                            : I18n.get('Add an account')}
                    </Box>
                </Grid.Col>
            </Grid>
        </Paper>
    );
};
