import {I18n} from '@aws-amplify/core';

import {useNavigate} from 'react-router-dom';

import {
    Center,
    Stack,
    Title
} from '@mantine/core';
import {AiOutlineHome} from 'react-icons/ai';
import {NextButton} from '@pac/platform-ui-components';

export const OfflinePage = () => {
    const navigate = useNavigate();

    return (
        <Center>
            <Stack>
                <Title order={1}>{I18n.get('You are offline')}</Title>
                <NextButton
                    type="button"
                    leftSection={<AiOutlineHome/>}
                    onClick={(event) => {
                        event.preventDefault();
                        navigate('/');
                    }}
                >
                    {I18n.get('Home')}
                </NextButton>
            </Stack>
        </Center>
    );
};
