import {RecordForm} from '../forms/RecordForm';
import {useSignInProfile} from "../hooks/useSignInProfile.ts";

export const AddPage = () => {

    const {
        record,
        handleAddSignInProfile
    } = useSignInProfile()

    return (
        <RecordForm
            record={record}
            handleSubmit={handleAddSignInProfile}
        />
    );
};
