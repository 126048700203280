import {useEffect} from 'react';
import {
    useNavigate,
    useParams
} from 'react-router-dom';

import {I18n} from '@aws-amplify/core';

import {
    Box,
    Button,
    Center,
    Container,
    List,
    Stack,
    Title
} from '@mantine/core';

import {DisplayUserState} from '../../../components/DisplayUserState';
import {useAccounts} from "../hooks/useAccounts";
import {AiOutlineHome} from "react-icons/ai";

export const NotFoundPage = () => {

    let {email} = useParams()
    const navigate = useNavigate();

    const {
        accounts,
        handleFetchAccounts
    } = useAccounts();

    useEffect(() => {
        handleFetchAccounts();
    }, []);

    const accountsTest = [
        {emailAddress: 'test1@example.com'},
        {emailAddress: 'test2@example.com'},
        {emailAddress: 'test3@example.com'},
    ];

    return (
        <Center>
            <Stack gap="xl">
                <Title order={2}>{I18n.get('Account not found')}</Title>
                <Stack>
                    <Title order={5}>Searched for this:</Title>
                    <Box>{email}</Box>
                </Stack>
                <Stack>
                    <Title order={5}>But found this:</Title>
                    <List>
                        {accountsTest.map((account, index) => (
                            <List.Item key={index}>{account.emailAddress}</List.Item>
                        ))}
                    </List>
                </Stack>
                <Stack>
                    <Title order={5}>When comparing to the list of accounts the result was this:</Title>
                    <List>
                        {accountsTest.map((account, index) => (
                            <List.Item
                                key={index}>{(account.emailAddress.toLowerCase() === email?.toLowerCase()).toString()}</List.Item>
                        ))}
                    </List>
                </Stack>

                <Container>
                    <Button onClick={() => {
                        return navigate('/');
                    }} leftSection={<AiOutlineHome/>}>Start Over</Button>
                </Container>

                {<DisplayUserState accounts={accounts} enabled={false}/>}
            </Stack>
        </Center>
    );
};
