import {
    Box,
    Center,
    Group
} from '@mantine/core';

import {
    useNavigate,
    useParams
} from 'react-router-dom';
import {useServiceAgreement} from "../hooks/useServiceAgreement";
import {useAccount} from "../../accounts/hooks/useAccount";
import {
    AiOutlineArrowRight,
    AiOutlineCheck
} from "react-icons/ai";
import {NextButton} from '@pac/platform-ui-components';

export const AcceptanceProblemPage = () => {

    const navigate = useNavigate();

    const {account: accountId} = useParams();

    const {
        record: account,
    } = useAccount(accountId);

    if (account === null) {
        throw new Error('Account is null');
    }

    const {handleClear} = useServiceAgreement()

    return (
        <Center>
            <Group p={'xl'}>
                <AiOutlineCheck size={'3em'} color={'red'}/>
                <Box p={'xl'}>Acceptance Operation Error</Box>
                <NextButton variant={'outline'} rightSection={<AiOutlineArrowRight size={'1em'} color={'green'}/>}
                            onClick={() => {
                                handleClear()
                                return navigate(`/accounts`);
                            }}>Continue</NextButton>
            </Group>
        </Center>

    );
};

