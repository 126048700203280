import {
    Navigate,
    useParams
} from 'react-router-dom';

import {CredentialsForm} from '../forms/CredentialsForm';

import {FooterRow} from '../components/FooterRow';
import {useAccount} from "../../accounts/hooks/useAccount";
import {useCredentials} from "../hooks/useCredentials";
import {Stack} from '@mantine/core';


export const IndexPage = () => {

    const {account: accountId} = useParams();

    const {
        record: account,
    } = useAccount(accountId);

    const {
        forcePasswordChange,
        handleSignIn,
    } = useCredentials();

    if (typeof account === 'undefined' || account === null) {
        return <Navigate to="/404"/>;
    }

    if (forcePasswordChange === true) {
        return <Navigate to={`/accounts/${account.id}/change-password`}/>;
    }

    return (
        <Stack gap={'xl'}>
            <CredentialsForm
                handleSubmit={handleSignIn}
                emailAddress={account['emailAddress'] as string}
            />
            <FooterRow account={account}/>
        </Stack>
    );
};
