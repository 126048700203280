import {Table} from "@mantine/core";
import {UserAgreement} from "../types.ts";


export const UserAgreementsTable = ({agreements}: { agreements: UserAgreement[] }) => (<Table.Tbody>
    <Table.Tr>
        <Table.Th>Name</Table.Th>
        <Table.Th>View</Table.Th>
        <Table.Th>Accepted By</Table.Th>
        <Table.Th>Accepted On</Table.Th>
        <Table.Th>From IP</Table.Th>
    </Table.Tr>
    {Array.isArray(agreements) && agreements.map((agreement, index) => {
        return (
            <Table.Tr key={index}>
                <Table.Td>{agreement.name}</Table.Td>
                {/*<Table.Td><NavLink to={agreement.viewPath}>View</NavLink></Table.Td>*/}
                <Table.Td>{agreement.acceptedBy}</Table.Td>
                <Table.Td>{agreement.acceptedOn}</Table.Td>
                <Table.Td>{agreement.acceptedIpAddress}</Table.Td>
            </Table.Tr>
        );
    })}
</Table.Tbody>);
