import {
    useNavigate,
    useParams
} from 'react-router-dom';

import {I18n} from '@aws-amplify/core';

import {
    Box,
    Center,
    Group,
    Stack
} from '@mantine/core';
import {useAccount} from "../../accounts/hooks/useAccount.ts";
import {
    NextButton,
    PlatformFieldset
} from "@pac/platform-ui-components";


export const SelectionPage = () => {

    const {account: accountId} = useParams();
    const {
        record: account,
    } = useAccount(accountId);

    const navigate = useNavigate();

    const handleCorporateAccount = () => {
        navigate(`/accounts/${account.id}/reset-password/federated-user`);
    };

    const handleUsernameAndPassword = () => {
        navigate(`/accounts/${account.id}/reset-password/request`);
    };

    return (
        <PlatformFieldset legend={I18n.get('Reset Password')}>
            <Center>
                <Stack py={'xl'}>
                    <Center>How do you sign into Panasonic NEXT Cloud?</Center>

                    <Group my={'md'}>
                        <Box>
                            <NextButton
                                onClick={handleCorporateAccount}
                            >
                                I use corporate account
                            </NextButton>
                        </Box>
                        <Center py="sm">or</Center>
                        <Box>
                            <NextButton
                                onClick={handleUsernameAndPassword}
                            >
                                I use email and password
                            </NextButton>
                        </Box>
                    </Group>

                </Stack>
            </Center>
        </PlatformFieldset>
    );
};
