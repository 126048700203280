import {useEffect} from 'react';
import {useSignInProfiles} from "../hooks/useSignInProfiles.ts";
import {
    ActionIcon,
    Badge,
    Center,
    Group,
    Table,
    Text
} from "@mantine/core";
import {useNavigate} from "react-router-dom";
import {useSignInProfile} from "../hooks/useSignInProfile.ts";
import {
    AiOutlineCheck,
    AiOutlineDelete,
    AiOutlineSelect
} from "react-icons/ai";
import {
    NextAddButton,
    PlatformFieldset
} from '@pac/platform-ui-components';
import {I18n} from 'aws-amplify';

export const IndexPage = () => {

    const navigate = useNavigate();

    const {
        signInProfiles,
        handleFetchSignInProfiles,
    } = useSignInProfiles();

    const {
        handleDeleteSignInProfile,
        handleMakeActive
    } = useSignInProfile();

    useEffect(() => {
        handleFetchSignInProfiles();
    }, []);


    const rows = () => {
        return signInProfiles.map((option) => {

            return (
                <Table.Tr key={option.id}>
                    <Table.Td>{option.id}</Table.Td>
                    <Table.Td><Center>{option.isDefault ? <AiOutlineCheck/> : 'no'}</Center></Table.Td>
                    <Table.Td>
                        <Center>
                            {option.isActive ?
                                <Badge color={'green'} title={'Active'}>{I18n.get('Y')}</Badge>
                                :
                                <ActionIcon
                                    variant={'subtle'}
                                    onClick={() => handleMakeActive(option.id as string)}><AiOutlineSelect
                                    title={'Make Active'}/></ActionIcon>}
                        </Center>
                    </Table.Td>
                    <Table.Td><Center>{option.language}</Center></Table.Td>
                    <Table.Td><Center>{option.showReturnTo ? 'yes' : 'no'}</Center></Table.Td>
                    <Table.Td><Center>{option.passwordAndSso ? 'yes' : 'no'}</Center></Table.Td>
                    <Table.Td><Center>{option.verbosity}</Center></Table.Td>
                    <Table.Td>
                        {!option.isDefault ? <ActionIcon
                                variant={'subtle'}
                                onClick={() => {
                                    handleDeleteSignInProfile(option.id as string)
                                    handleFetchSignInProfiles();
                                }}><AiOutlineDelete/></ActionIcon> :
                            <ActionIcon disabled={true}><AiOutlineDelete/></ActionIcon>}
                    </Table.Td>
                </Table.Tr>
            );
        });
    };


    return (
        <PlatformFieldset legend={I18n.get('Sign-in Profiles')}>
            <Group justify={'end'} my={'lg'}>
                <NextAddButton onClick={() => navigate('add')}>{I18n.get('Add Profile')}</NextAddButton>
            </Group>
            <Table striped horizontalSpacing="sm" verticalSpacing="lg">
                <Table.Thead>
                    <Table.Tr>
                        <Table.Th>ID</Table.Th>
                        <Table.Th><Center><Text
                            fz={'small'}>{I18n.get('Default Profile')}</Text></Center></Table.Th>
                        <Table.Th><Center><Text fz={'small'}>{I18n.get('Active')}</Text></Center></Table.Th>
                        <Table.Th><Center><Text fz={'small'}>{I18n.get('Language')}</Text></Center></Table.Th>
                        <Table.Th><Center><Text fz={'small'}>{I18n.get('Show Return To')}</Text></Center></Table.Th>
                        <Table.Th><Center><Text
                            fz={'small'}>{I18n.get('Method Selection')}</Text></Center></Table.Th>
                        <Table.Th><Center><Text fz={'small'}>{I18n.get('Verbosity')}</Text></Center></Table.Th>
                        <Table.Th><Center><Text fz={'small'}>{I18n.get('Delete')}</Text></Center></Table.Th>
                    </Table.Tr>
                </Table.Thead>
                <tbody>
                {rows()}
                </tbody>
            </Table>
        </PlatformFieldset>
    );


};
